import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorStatus } from 'api';
import { LP_STATUS_4, LP_STATUS_12 } from 'config/constants';
import { useApp } from 'context/AppContext';
import { Component } from './Error.styled';

interface IProps {
    status?: ErrorStatus | typeof LP_STATUS_4 | typeof LP_STATUS_12
}

export const Error:FC<IProps> = ({ status }) => {
    const { t } = useTranslation();
    const { flowUrl } = useApp();

    let ErrorContent: JSX.Element;
    const mainPage = `/`;

    if (status === LP_STATUS_4) {
        ErrorContent = (<>
            <h2>{ t('error.lp_status_4') }</h2>
            <a href={mainPage}>{ t('btn.return_to_main') }</a>
        </>);
    }

    else if (status === LP_STATUS_12) {
        ErrorContent = (<>
            <h2>{ t('error.lp_status_12') }</h2>
            <a href={mainPage}>{ t('btn.return_to_main') }</a>
        </>);
    }

    else if (status === 401) {
        ErrorContent = (<>
            <h2>{ t('error.http_status_401') }</h2>
            <a href={flowUrl}>{ t('btn.authorize') }</a>
            <a href={mainPage}>{ t('btn.return_to_main') }</a>
        </>);
    }

    else if (status === 402) {
        ErrorContent = (<>
            <h2>{ t('error.http_status_402') }</h2>
            <a href={mainPage}>{ t('btn.return_to_main') }</a>
        </>);
    }

    else if (status === 403) {
        ErrorContent = (<>
            <h2>{ t('error.http_status_403') }</h2>
            <a href={flowUrl}>{ t('btn.activate') }</a>
            <a href={mainPage}>{ t('btn.return_to_main') }</a>
        </>);
    }

    else {
        ErrorContent = (<>
            <h2>{ t('title.error') }</h2>
            <a href={mainPage}>{ t('btn.return_to_main') }</a>
        </>);
    }

    return (
        <Component>
            { ErrorContent }
        </Component>
    );
};
